import React, { useState, useEffect } from "react";
import { invite_link } from "../../../config";
import { InviteGet } from "../../../api/api";

import startImage from "../../../assets/tasks/bottom.png";
import doneImage from "../../../assets/tasks/Done.png";

import energyImg from "../../../assets/tasks/energy.png";
import coinImg from "../../../assets/tasks/coin.png";
import plusImg from "../../../assets/tasks/plus.png";

import crystalImg from "../../../assets/tasks/crystal.png";
import crystalTG from "../../../assets/tasks/crystal-tg.png";
import crystalTT from "../../../assets/tasks/crystal-tt.png";
import crystalIN from "../../../assets/tasks/crystal-in.png";

// import "./TaskItem.scss";

export const TaskItem = (props) => {
  const [myLink, setMyLink] = useState(invite_link + `?start=&text=`);
  const [myLinkData, setMyLinkData] = useState({});

  const generate_link = async () => {
    const linkData = await InviteGet(props.user_id);
    if (linkData) {
      setMyLinkData(linkData);
    }
  };

  useEffect(() => {
    if (myLinkData) {
      console.log(JSON.stringify(myLinkData));
      setMyLink(invite_link + `?start=${myLinkData.code}&text=`);
    }
  }, [myLinkData]);

  useEffect(() => {
    generate_link();
  }, []);

  const startTask = async () => {
    try {
      if (props.task.social === "frends") {
        props.tg.openTelegramLink(myLink + `${props.lang.invite_msg()}`);
      } else if (props.task.social === "tg") {
        props.tg.openTelegramLink(props.task.link);
      } else {
        props.tg.openLink(props.task.link);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const buttonStyle = {
    backgroundImage: `url(${
      props.task.status === 0
        ? startImage
        : props.task.status === 1
        ? doneImage
        : startImage
    })`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "transparent",
    width: "70px",
    height: "50px",
    border: "none",
    color: "white",
  };

  const isFriends = props.task.social === "frends";

  return (
    <div className={`${isFriends ? "friends" : "telegram"} task`}>
      {/* <img src={crystalImg} className="crystalYT" alt="crystal" />
      <p className="task-item-name">{props.task.name}</p>
      <div className="task-item-text">
        <span>{props.task.earn}</span>
      </div>*/}
      <img
        src={isFriends ? crystalIN : crystalTG}
        className="crystalYT"
        alt="crystal"
      />
      <p className="text">{props.task.name}</p>
      <div className="reward" style={{ width: "245px" }}>
        <span className="tokens">
          <img src={plusImg} className="plus" alt="+" /> {props.task.earn}
          <img src={coinImg} className="coin" alt="coin" />
        </span>
        <span className="energy">
          FULL+1
          <img src={energyImg} className="energy" alt="energy" />
        </span>
      </div>
      <button
        // className="task-status-button"
        onClick={() => startTask()}
        disabled={props.task.status === 1}
        style={buttonStyle}
        className={`plusbutton`}
      ></button>
      {/* <img
        src={el.status === 2 ? claimImg : el.status === 0 ? buttonImg : doneImg}
        className={`plusbutton ${el.status === 1 && "disabled"}`}
        alt="button"
        onClick={() => StartTask(el)}
      /> */}
    </div>
  );
};
