import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./DailyTasks.module.scss";

import { useSelector } from "react-redux";

import pepeIcon from "../../../assets/wallet/PEPE.png";
import shibIcon from "../../../assets/wallet/SHIB.png";
import usdtIcon from "../../../assets/wallet/USDT.png";
import tonIcon from "../../../assets/wallet/TON.png";
import scfaIcon from "../../../assets/wallet/SCFA.png";
import coinIcon from "../../../assets/tasks/daily-tasks/coin.png";

import checkIcon from "../../../assets/tasks/daily-tasks/check.png";

const DailyBonus = () => {
  const { userId } = useSelector((state) => state.user);

  const [dailyRewards, setDailyRewards] = useState([]);
  const [loading, setLoading] = useState(false);

  // Функция для получения данных о бонусах
  const fetchDailyRewards = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.spacefarm.ink/api/v1/daily_login?user_id=${userId}`
      );
      if (response.data.status === 1) {
        setDailyRewards(response.data.days);
      }
    } catch (error) {
      console.error("Error fetching daily rewards:", error);
    } finally {
      setLoading(false);
    }
  };

  // Функция для запроса награды
  const claimDailyReward = async (date) => {
    try {
      const response = await axios.post(
        `https://api.spacefarm.ink/api/v1/daily_login/claim`,
        {
          user_id: userId,
          date,
        }
      );
      if (response.data.status === 1) {
        // alert(response.data.data.message);
        fetchDailyRewards(); // Обновить список после получения награды
      }
    } catch (error) {
      console.error("Error claiming daily reward:", error);
    }
  };

  useEffect(() => {
    fetchDailyRewards();
  }, [userId]);

  // Сопоставление наград с иконками
  const getRewardIcon = (type) => {
    switch (type) {
      case "SCFA":
        return scfaIcon;
      case "SFT":
        return coinIcon;
      case "PEPE":
        return pepeIcon;
      case "SHIB":
        return shibIcon;
      case "USDT":
        return usdtIcon;
      case "TON":
        return tonIcon;
      default:
        return checkIcon;
    }
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <p className={styles.loading}>Loading...</p>
      ) : (
        <div className={styles.grid}>
          {dailyRewards.map((reward, index) => (
            <div
              key={index}
              className={`${styles.card} ${
                reward.is_claimed || reward.status === "future"
                  ? styles.claimed
                  : styles.active
              }`}
              onClick={() =>
                reward.status === "today" && !reward.is_claimed
                  ? claimDailyReward(reward.date)
                  : null
              }
            >
              <div className={styles.day}>Day {index + 1}</div>
              <div className={styles.reward}>
                <p className={styles.rewardAmount}>
                  {reward.is_claimed ? "" : `+${reward.reward.amount}`}
                </p>
                <img
                  src={
                    reward.is_claimed
                      ? checkIcon
                      : getRewardIcon(reward.reward.type)
                  }
                  alt="Reward"
                  className={styles.rewardIcon}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DailyBonus;
