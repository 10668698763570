import React from "react";
import { useSelector } from "react-redux";
import hypeBut from "../../assets/hype/hype-min.png";
import wheelBut from "../../assets/wheel/wheel-page.png";
import Balance from "./Balance/Balance";
import styles from "./Header.module.scss";
import Level from "./Level/Level";

import SystemButtons from "../SystemButtons/SystemButtons";

export const Header = ({ balance, page, setPage, onClickEnergy }) => {
  const user = useSelector((state) => state.user);

  return (
    <header className={styles.header}>
      <div className={styles.topHeader}>
        <div className={styles.leftHeader}>
          <Balance
            balance={balance}
            onClick={() => setPage("wallet")}
            className={styles.balance}
          />
          {page === "planets" && (
            <div className={styles.fortuneContainer}>
              <img
                onClick={() => setPage("hype")}
                className={styles.hypeButton}
                src={hypeBut}
                alt="Hype"
              />
              <img
                onClick={() => setPage("wheel")}
                className={styles.wheelButton}
                src={wheelBut}
                alt="Wheel"
              />
            </div>
          )}
        </div>
        <div className={styles.rightHeader}>
          <Level level={user.level} />
          <div
            className={styles.energyContainer}
            onClick={onClickEnergy}
            title="Energy"
          >
            <p>
              {user?.energy}/{user.energy_limit}
            </p>
          </div>
          <SystemButtons page={page} />
        </div>
      </div>
    </header>
  );
};
